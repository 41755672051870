.viewer-container.viewer-backdrop.viewer-fixed.viewer-fade.viewer-transition.viewer-in {
  cursor: default;
}

.viewer-button {
  background-position: center;
  background-image: url(images/close.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  top: 1%;
  right: 1%;
  width: 40px;
  height: 40px;
  outline: white 2px solid;
}

.viewer-title {
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  opacity: 1;
  margin-bottom: 1svh;
}
.viewer-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.viewer-transition {
  transition: all 0.4s ease;
}

.viewer-canvas > img {
  border-radius: 10px;
}

.viewer-list {
  height: 100%;
}
.viewer-list > li {
  height: 50px;
  width: 70px;
}

.viewer-list > li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.viewer-toolbar > ul {
  height: 40px;
  overflow: visible;
  margin-bottom: 2svh;
  padding: 4px;
}

.viewer-toolbar > ul > .viewer-large {
  height: 100%;
  width: 40px;
  overflow: visible;
  margin: 0;
  margin-inline: 5px;
  outline: white 2px solid;
}

.viewer-toolbar > ul > .viewer-large:hover {
  outline: #f90 2px solid;
}

.viewer-reset {
  background-position: center;
  background-image: url(images/reset.svg);
  background-size: 60%;
  background-repeat: no-repeat;
  content: 'Reset';
  overflow: visible;
}

.viewer-prev {
  background-position: center;
  background-image: url(images/previous.svg);
  background-size: 43%;
  background-repeat: no-repeat;
  content: 'Prev';
  overflow: visible;
  fill: white;
}

.viewer-next {
  background-position: center;
  background-image: url(images/next.svg);
  background-size: 43%;
  background-repeat: no-repeat;
  content: 'Prev';
  overflow: visible;
  fill: white;
}

.viewer-large::before {
  content: none;
}

.viewer-reset:focus {
  animation: fullSpin 0.75s ease-out;
  outline: white 2px solid !important;
  box-shadow: #f90 0 0 10px !important;
}

.viewer-reset:active {
  animation: none;
  background-image: url(images/resetf90.svg);
}

.viewer-close:before,
.viewer-flip-horizontal:before,
.viewer-flip-vertical:before,
.viewer-fullscreen-exit:before,
.viewer-fullscreen:before,
.viewer-next:before,
.viewer-one-to-one:before,
.viewer-play:before,
.viewer-prev:before,
.viewer-reset:before,
.viewer-rotate-left:before,
.viewer-rotate-right:before,
.viewer-zoom-in:before,
.viewer-zoom-out:before {
  background-image: none;
  background-repeat: no-repeat;
  color: transparent;
  display: block;
  font-size: 0;
  height: 40px;
  line-height: 0;
  width: 40px;
}

.viewer-loading:after {
  /* position: absolute; */
  /* left: 50%;  */
  /* top: 50vh; */
  /* translate: -50% -50%; */
  z-index: 100;
  animation: spin;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  scale: 120%;
  /* filter: invert(var(--invertLogo)); */
  background-image: url(images/YA\ logo.svg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0;
  border: none;
}

.viewer-open {
  overflow: visible !important;
}

.viewer-tooltip {
  font-family: 'Noto sans';
  font-size: 1.2rem;
  font-weight: bolder;
  padding: 0.5em;
  width: auto;
  height: auto;
  background-color: #0008;
}
