.viewer-container.viewer-backdrop.viewer-fixed.viewer-fade.viewer-transition.viewer-in {
  cursor: default;
}

.viewer-button {
  background-image: url("close.0efe9add.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  outline: 2px solid #fff;
  width: 40px;
  height: 40px;
  top: 1%;
  right: 1%;
}

.viewer-title {
  color: #fff;
  opacity: 1;
  margin-bottom: 1svh;
  font-family: Noto Sans, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}

.viewer-backdrop {
  background-color: #000000e6;
}

.viewer-transition {
  transition: all .4s;
}

.viewer-canvas > img {
  border-radius: 10px;
}

.viewer-list {
  height: 100%;
}

.viewer-list > li {
  width: 70px;
  height: 50px;
}

.viewer-list > li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.viewer-toolbar > ul {
  height: 40px;
  margin-bottom: 2svh;
  padding: 4px;
  overflow: visible;
}

.viewer-toolbar > ul > .viewer-large {
  margin: 0;
  outline: 2px solid #fff;
  width: 40px;
  height: 100%;
  margin-inline: 5px;
  overflow: visible;
}

.viewer-toolbar > ul > .viewer-large:hover {
  outline: 2px solid #f90;
}

.viewer-reset {
  content: "Reset";
  background-image: url("reset.41937f25.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  overflow: visible;
}

.viewer-prev {
  content: "Prev";
  fill: #fff;
  background-image: url("previous.5081cb59.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 43%;
  overflow: visible;
}

.viewer-next {
  content: "Prev";
  fill: #fff;
  background-image: url("next.e0e4adfa.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 43%;
  overflow: visible;
}

.viewer-large:before {
  content: none;
}

.viewer-reset:focus {
  animation: .75s ease-out fullSpin;
  outline: 2px solid #fff !important;
  box-shadow: 0 0 10px #f90 !important;
}

.viewer-reset:active {
  background-image: url("resetf90.86f78296.svg");
  animation: none;
}

.viewer-close:before, .viewer-flip-horizontal:before, .viewer-flip-vertical:before, .viewer-fullscreen-exit:before, .viewer-fullscreen:before, .viewer-next:before, .viewer-one-to-one:before, .viewer-play:before, .viewer-prev:before, .viewer-reset:before, .viewer-rotate-left:before, .viewer-rotate-right:before, .viewer-zoom-in:before, .viewer-zoom-out:before {
  color: #0000;
  background-image: none;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.viewer-loading:after {
  z-index: 100;
  background-image: url("YA logo.bbfbf71f.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 0;
  animation: 3s infinite forwards spin;
  scale: 120%;
}

.viewer-open {
  overflow: visible !important;
}

.viewer-tooltip {
  background-color: #0008;
  width: auto;
  height: auto;
  padding: .5em;
  font-family: Noto sans;
  font-size: 1.2rem;
  font-weight: bolder;
}
/*# sourceMappingURL=index.53176efb.css.map */
